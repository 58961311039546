.container {
  margin: 10px 10px 0 10px;
}

.editorContainer {
  background: #eee;
  width: 30rem;
  max-width: 100%;
  height: calc(100vh - 30px - 2rem - 2.5rem);
  overflow-y: scroll;
  padding: 0.3rem;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 10px 0 0 0;
  }
  .editorContainer {
    padding: 0;
    width: 100%;
  }
}

.toolbar {
  margin-bottom: 5px;
  display: flex;
  background: rgb(243, 240, 240);
  box-shadow: 0 0 1px #333;
  height: 2rem;
}

.toolbar .iconButton {
  width: 1.8rem;
  padding: 0.3rem 0.1rem 0.3rem 0.1rem;
  text-align: center;
}
.toolbar .iconButton:hover {
  background: rgb(218, 214, 214);
}

.toolbar .space {
  margin-left: 5px;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.popover {
  position: absolute;
  z-index: 2;
}