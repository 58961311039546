.container {
  height: calc(100vh - 2.5rem);
}

.toolbar {
  height: 2rem;
  display: flex;
}

.toolbar .cntContainer {
  margin-left: auto;
  margin-right: 1rem;
  color: #555;
}
 
.scrollContainer {
  height: calc(100% - 2rem - 5px);
  overflow-y: scroll;
}