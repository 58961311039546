header {
  background: rgb(20, 96, 158);
  color: #fff;
  margin: 0;
  padding: 0.5rem;
  height: 1.5rem;
}
@media print{
  header {
    display: none;
  }
}
@page {
  size: A4 landscape;
}

.flex-container {
  display: grid;
  grid-template-columns: auto 1fr;
}

#SHOW_TEST {
  position: fixed;
  right: 10px;
  bottom: 10px;
}