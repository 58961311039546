.global_wrapper {
  width: 100vw;
}

.hero_wrapper {
  min-height: 100vh;
  background: #f83600;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f83600, #fe8c00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f83600, #fe8c00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.hero_wrapper h1 {
  color: #fff;
  margin: 0;
  padding: 1rem;
  font-size: 20px;
  font-weight: bold;
}

.hero_header_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: 0 auto;
}

.header_read {
  color: #fff;
  padding: 0 1rem;
}
  .header_read .text1 {
    font-size: 35px;
  }
  .header_read .text2 {
    font-size: 22px;
  }

.header_paper_img {
  text-align: center;
  align-self: center;
}

@media screen and (max-width: 650px) {
  .hero_header_grid {
    grid-template-columns: 1fr;
  }
  .header_paper_img {
    display: none;
  }
}


.btn,
a.btn,
button.btn {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
}
.btn_orange,
a.btn_orange {
  color: rgb(207, 6, 6);
  background-color: #fff;
}
.btn_orange:hover,
a.btn_orange:hover {
  color: rgb(226, 65, 65);
  background: #fff;
}

a.btn_orange {
   border-radius: 100vh;
}