.wrapper {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.pageRect {
  stroke: rgb(255, 198, 145);
  fill: #fff;
  stroke-width: 2;
}

.pageNo {
  text-align: center;
}

.bold {
  font-style: italic;
}