.container {
  width: 29cm;

}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 26cm;
}

.newpage {
  page-break-before: always;
}